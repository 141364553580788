import axios from "axios";
import config from "../../config.json";
import { isMobile } from 'react-device-detect';
// import axiosRetry from 'axios-retry';
// axiosRetry(axios, { retries: 3 });
// import api from "./api";

const token = localStorage.getItem("jwtToken");

//////////// QR CODE STRAT///////
export const walletConnect = async (data) => {
  const walletCofig = {
    baseURL: config.LOCAL_API_URL,
    headers: {
      Authorization: `Basic ${token}`,
    },
    data,
    method: "post",
    url: "xumm/connect",
  };

  try {
    const wallet = await axios(walletCofig);
    if (wallet.status === 200) {
      return wallet;
    }
  } catch (e) {
    return e.response;
  }
};
//////////// QR CODE END///////
//////////// ACCOUNT DETAIL STRAT///////
export const accountDetail = async (data) => {
  const uuidCofig = {
    baseURL: config.LOCAL_API_URL,
    headers: {
      Authorization: `Basic ${token}`,
    },
    data,
    method: "post",
    url: "xumm/accountDetail",
  };

  try {
    const accountDetail = await axios(uuidCofig);
    if (accountDetail.status === 200) {
      return accountDetail;
    }
  } catch (e) {
    return e.response;
  }
};
//////////// ACCOUNT DETAIL END///////

//////////// GET BALANCE STRAT///////
export const getBalanceDetail = async (data) => {
  const getBalanceCofig = {
    baseURL: config.LOCAL_API_URL,
    headers: {
      Authorization: `Basic ${token}`,
    },
    data,
    method: "post",
    url: "xumm/getBalance",
  };

  try {
    const balance = await axios(getBalanceCofig);
    if (balance.status === 200) {
      return balance;
    }
  } catch (e) {
    return e.response;
  }
};
//////////// GET BALANCE END///////

//////////// GET registrationFee///////

export const registrationFeeApi = async (data) => {
  const getBalanceCofig = {
    baseURL: config.LOCAL_API_URL,
    headers: {
      Authorization: `Basic ${token}`,
    },
    data,
    method: "post",
    url: "xumm/registrationFee",
  };

  try {
    const balance = await axios(getBalanceCofig);
    if (balance.status === 200) {
      return balance;
    }
  } catch (e) {
    return e.response;
  }
};
// export const registrationFeeApi = async (data) => {
//   const getBalanceCofig = {
//     baseURL: config.LOCAL_API_URL,
//     headers: {
//       Authorization: `Basic ${token}`,
//     },
//     data,
//     method: "post",
//     url: "xumm/registrationFee",
//   };

//   try {
//     const balance = await axios(getBalanceCofig);
//     if (balance.status === 200) {
//       const { uuid } = balance.data
//       const request = {
//         uuid,
//         token: data.token,
//         account: data.account
//       }
//       const status =  await checkTransactionStatus(request);

//       console.log("balance", status)
//       return status;
//     }
//   } catch (e) {
//     return e.response;
//   }
// };
// //////////// GET registrationFee///////

// async function checkTransactionStatus(data) {
//   let retries = 0;
//   const maxRetries = 10; // Stop polling after 10 tries (~30 seconds)

//   // console.log("⏳ Checking status...", data);

//   const interval = setInterval(async () => {
//       try {
//             const registrationConfig = {
//               baseURL: config.LOCAL_API_URL,
//               headers: {
//                 Authorization: `Basic ${token}`,
//               },
//               data,
//               method: "post",
//               url: "xumm/checkRegistrationFee",
//             };

//           if (++retries >= maxRetries) {
//               console.log("⚠️ Stopping polling after max retries.");
//               clearInterval(interval);
//           }
//           // start from here 
          
//           const resp = await axios(registrationConfig);
//           console.log("result success", resp);
//           clearInterval(interval); // Stop polling
//           return resp;

//       } catch (error) {
//           console.error("❌ Error checking status:", error);
//           return error.response
//       }
//   }, 3000); // Check every 3 seconds
// }

//////////// DIS CONNECT STRAT///////
export const walletDisConnect = async (data) => {
  const walletDisCofig = {
    baseURL: config.LOCAL_API_URL,
    headers: {
      Authorization: `Basic ${token}`,
    },
    data,
    method: "post",
    url: "xumm/disConnect",
  };

  try {
    const disCon = await axios(walletDisCofig);
    if (disCon.status === 200) {
      return disCon;
    }
  } catch (e) {
    return e.response;
  }
};
//////////// DIS CONNECT END///////


// axios.interceptors.response.use(
//   response => response,
//   error => {
//     console.log("Interceptor Error:", error);
//     return Promise.reject(error);
//   }
// );

// Create payment before create an NFT

export const createPayment = async (data) => {
  const paymentConfig = {
    baseURL: config.LOCAL_API_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Basic ${token}`,
    },
    data,
    method: "post",
    url: "nft/createPayment",
  };

  try {
  	const payment = await axios(paymentConfig);
  		return payment;
    } catch (error) {
      return error.response
     }
  };



  export const checkPaymentStatus = async ({ uuid } ) => {
    const paymentConfig = {
      baseURL: config.LOCAL_API_URL,
      headers: {
        Accept: "application/json",
      },
      method: "get",
      url: "nft/check-payment-status/" + uuid,
    };
  
    try {
      const paymentStatus = await axios(paymentConfig);
      return paymentStatus
      } catch (error) {
        console.error(error.response, "❌ Payment Status Error:", error.message);
        return error.response
       }
    };




