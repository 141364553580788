import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Document, Page, pdfjs } from "react-pdf";
import FBXViewer from "./FbxViewer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PreviewFiletype(props) {
	const [pageNumber, setNumPages] = useState(1);


	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	return (
		<>
			{(() => {
				if (props.fileType === "image" || props.fileType === "gif") {
					return (
						<div className="main-img md-3 img_preview">
							<Card.Img
								variant="top"
								src={props.image}
								className="min-height-200-new11 img_max_preview"
							/>
						</div>
					);
				} else if (props.fileType === "video") {
					return (
						<>
							<div className="main-img img_preview">
								<div className="video-tag-common img_max_preview">
									<video
										className="video-player"
										preload="metadata"
										controls
										autoPlay
										muted
										controlsList="nodownload"
									>
										    <track  kind="captions"  label=""/>
										<source src={props.image + "#t=0.2"} />
									</video>
								</div>
							</div>
						</>
					);
				} else if (props.fileType === "audio") {
					return (
						<>
							<div className="main-img img_preview previewmusiccstm">
								<div className="audio-video-tag-common audio-height_img_preview img_max_preview ">
									<audio controls controlsList="nodownload">
									<track  kind="captions"  label=""/>
										<source src={props.image} />
									</audio>
								</div>
							</div>
						</>
					);
				} else if (props.fileType === "application") {
					// console.log(props.image, "props.image")
					return (
						<div className="pdf-style pdf-preview-cstm">
							<Document
								className="pdf-style"
								file={props.image}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								<Page pageNumber={pageNumber} className="pdf-style" />
							</Document>
						</div>
					);
				}else if (props.fileType === "fbx") {
					return <div className="main-img md-3 img_preview" id="fbx-id">
						<FBXViewer fbxPath={props.image} width={133} height={122} />
					</div>
				}	
			})()}
			</>
	);
}

export default PreviewFiletype;
