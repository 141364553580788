import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const FBXViewer = ({ fbxPath }) => {
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Function to update dimensions
  const updateDimensions = () => {
    const container = document.getElementById("fbx-id");
    if (container) {
      setDimensions({
        width: container.clientWidth,
        height: container.clientHeight,
      });
    }
  };

  useEffect(() => {
    updateDimensions(); // Set initial dimensions
    window.addEventListener("resize", updateDimensions); // Listen for resize

    return () => {
      window.removeEventListener("resize", updateDimensions); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    if (!fbxPath || dimensions.width === 0 || dimensions.height === 0) return;

    initScene();
    loadModel(fbxPath);

    return () => cleanupScene(); // Cleanup on component unmount
  }, [fbxPath, dimensions]);

  const initScene = () => {
    if (!mountRef.current) return;

    while (mountRef.current.firstChild) {
      mountRef.current.removeChild(mountRef.current.firstChild);
    }

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xeeeeee);
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(
      50,
      dimensions.width / dimensions.height,
      0.1,
      1000
    );
    camera.position.set(0, 0, 5);
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(dimensions.width, dimensions.height);
    rendererRef.current = renderer;
    mountRef.current.appendChild(renderer.domElement);

    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(5, 10, 5);
    scene.add(light);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.enableZoom = true;
    controls.target.set(0, 0, 0);
    controls.update();

    animate(controls);
  };

  const loadModel = (url) => {
    if (!url) return;

    const loader = new FBXLoader();
    loader.load(
      url,
      (object) => {
        object.scale.set(1, 1, 1);

        const box = new THREE.Box3().setFromObject(object);
        const size = box.getSize(new THREE.Vector3());
        const center = box.getCenter(new THREE.Vector3());

        object.position.sub(center);

        const maxSize = Math.max(size.x, size.y, size.z);
        const fitHeightDistance =
          maxSize / (2 * Math.tan((cameraRef.current.fov * Math.PI) / 360));
        const fitWidthDistance = fitHeightDistance / cameraRef.current.aspect;
        const distance = Math.max(fitHeightDistance, fitWidthDistance);
        cameraRef.current.position.set(center.x, center.y, center.z + distance * 1.5);
        cameraRef.current.lookAt(center);

        sceneRef.current.add(object);
      },
      (xhr) => {
        console.log(`Loading FBX: ${((xhr.loaded / xhr.total) * 100).toFixed(2)}%`);
      },
      (error) => {
        console.error("Error loading FBX:", error);
      }
    );
  };

  const cleanupScene = () => {
    if (rendererRef.current) {
      rendererRef.current.dispose();
    }
  };

  const animate = (controls) => {
    const loop = () => {
      requestAnimationFrame(loop);
      controls.update();
      rendererRef.current.render(sceneRef.current, cameraRef.current);
    };
    loop();
  };

  return (
    <div ref={mountRef} style={{ width: dimensions.width, height: dimensions.height }} />
  );
};

export default FBXViewer;
