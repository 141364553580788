export const Category = [
	"3D Art",
	"AI Art",
	"Animation",
	"Art",
	"Augmented Reality",
	"Avatar",
	"Collectibles",
	"Comic",
	"Crypto",
	"Digital Art",
	"Digital Asset",
	"Domain",
	"Fashion",
	"Fractional",
	"Gaming",
	"Gaming Asset",
	"Generative Art",
	"Governance Asset",
	"International",
	"Metaverse",
	"Mixed Reality",
	"Music",
	"Photography",
	"Physical Art",
	"Phygital Art",
	"Puzzle",
	"Tickets",
	"Token Gate NFT",
	"Trading Cards",
	"Utility",
	"Video",
	"Virtual Real Estate",
	"Virtual Reality"
]

export const MetaVerse = [
	"Non-Applicable",
	"Avatar",
	"Avatar - BackPack",
	"Avatar - Clothing Belt",
	"Avatar - Hair",
	"Avatar - Clothing Hat",
	"Avatar - Mask",
	"Avatar - Necklace",
	"Avatar - Clothing Overall",
	"Avatar - Clothing Scarf",
	"Avatar - Skin (overall)",
	"Avatar - Clothing Shoe",
	"Avatar - Clothing Torso",
	"Avatar - Watch",
	"Object - Animated Object",
	"Object - Building/Infrastructure",
	"Object - Item",
	"Object - Statue",
	"Object - Wall Picture",
	"Object - Weapon (blunt)",
	"Object - Weapon (firearm)",
	"Vehicle - Aeroplane",
	"Vehicle - Boat/Ship",
	"Vehicle - Car",
	"Vehicle - Helicopter",
	"Vehicle - Hovercraft",
	"Vehicle - Space Ship"
  ];